import Agent from "api/Agent";
import { useEffect, useState } from "react";
import { Col, Offcanvas } from "react-bootstrap";
import ChannelNavbar from "./components/ChannelNavbar/ChannelNavbar";
import Accounts from "./features/Accounts";
import Campaigns from "./features/Campaigns";
import styles from "./style.module.scss";



const CustomerDetailsOffCanvas = ({ show, setShow, id }) => {
  const [channel, setChannel] = useState("users");
  useEffect(() => { setChannel("users") }, [show])

  const {
    isLoading,
    isError,
    error,
    data
  } = Agent.Customers.GET.ID(id);


  const handleCloseOffcanvas = () => setShow(false);

  return (
    <Offcanvas
      show={show}
      onHide={handleCloseOffcanvas}
      placement="end"
      className={styles.leadDetailsOffCanvas}
    >
      <>
        <div className={styles.customerDetailsOffCanvas_Header}>
          <Offcanvas.Header
            className="flex-row-reverse justify-content-end align-items-baseline pb-0"
            closeButton
          >
            <Col className="d-flex justify-content-start ms-3 align-items-baseline">
              <h2 className="display-sm semibold">{data?.name}</h2>
            </Col>
          </Offcanvas.Header>
          <ChannelNavbar
            onClick={setChannel}
            defaultValue={"users"}

          />
        </div>
        <Offcanvas.Body className="p-4 ">
          {
            {
              "users": <Accounts
                id={id}
              />,
              "campaigns": <Campaigns customerID={id} />
            }[channel]

          }
        </Offcanvas.Body>
      </>
    </Offcanvas>
  );
};

export default CustomerDetailsOffCanvas;
