
import { useState } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import styles from './style.module.css'

/**
 *  * a Navbar with the following channels: ["accounts","campaigns"]
 * @constructor
 * @param {function} onClick - returns the selected channel
 * @param {string} defaultValue - set the default channel 
 */
export default function ChannelNavbar({onClick, defaultValue}) {
  const [activeChannel, setActiveChannel] = useState(defaultValue ?? null); // Initialize active channel state

  // Array of channel names
  const channels = [
    {label:'Användare', value: "users"}, 
    {label:'Kampanjer', value: "campaigns"}, 
    // {label: 'TikTok', value : CampaignOptionsTypes.TikTok}
  ]; // Add more channels as needed

  // Function to handle channel click
  const handleChannelClick = (channelName) => {
    setActiveChannel(channelName); // Set the clicked channel as active
    if (typeof onClick === "function" ) onClick(channelName); 
  };

  return (
    <Row className="d-flex flex-row justify-content-start px-4">
      <Row className="col-12 justify-content-end">
        {channels.map(({label, value}) => (
          <div
            role={"button"}
            key={label}
            onClick={() => handleChannelClick(value)} // Handle click event
            className={`col text-center text-sm semi-bold py-2 navItem ${activeChannel === value ? styles.activeNavItem : ''}`}
            style={{
              paddingRight: '20px',
              borderRight: '1px solid rgb(243 243 243)',
            }}
          >
            <span>{label}</span>
          </div>
        ))}
      </Row>
    </Row>
  );
}

