import "assets/styles/global.scss";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Layout from "layouts/Layout";
import Cookies from "js-cookie";
//PAGES
import Customers from "pages/customers/Customers";
import UsersAccounts from "pages/users/UsersAccounts";
import LoginPage from "pages/login/LoginPage";
import Home from "pages/home/Home";
import Settings from "pages/settings/Settings";
import CreateTargetGroup from "pages/create-target-group/CreateTargetGroup";
import MyTargetGroups from "pages/my-target-groups/MyTargetGroups";
import CreateCampaign from "pages/create-campaign/CreateCampaign";
import AppLayout from "layouts/AppLayout";
import {
  TypeOfCampaign,
  TargetGroup,
  Prioritize,
  AdChannel,
  BudgetAndSchedule,
  AdContent,
} from "features/campaign";
import Statistics from "pages/statistics/Statistics";
import MyCampaigns from "pages/my-campaigns/MyCampaigns";
import MyOffers from "pages/my-offers/MyOffers";
import MyLeads from "pages/my-leads/MyLeads";
import { useDispatch, useSelector } from "react-redux";
import {
  GetVisualEntities,
  GetVisualTypes,
  GetVisualsForStatisticsPage,
} from "features/stats/assets/statisticsSlice";
import useCheckUser from "hooks/useCheckUser";
import MyAccounts from "pages/my-accounts/MyAccounts";
import ForgotPassword from "pages/reset-password/ForgotPassword";
import ResetPassword from "pages/reset-password/ResetPassword";
import { removeSession } from "context/sessionSlice";
import { JobsTable } from "features/job";
import Jobs from "pages/my-jobs/Jobs";
import MyFilters from "pages/my-filters/MyFilters";
import CustomersSuccess from "pages/customers/success/CustomersSuccess";
import Activities from "pages/activities/Activities";
import Exports from "pages/exports/Exports";
import Statistics2 from "pages/statistics2/Statistics2";
import Lists from "pages/my-lists/Lists";

const MERINFO_THEME = process.env['REACT_APP_MERINFO_THEME'];
function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const session = Cookies.get("session");
  const userStatus = useCheckUser();
  const appInit = useCallback(async () => {
    try {
      await dispatch(GetVisualsForStatisticsPage());
      await dispatch(GetVisualEntities());
      await dispatch(GetVisualTypes());
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  console.log(window.location);

  const isExceptionRoute = () => {
    const currentPath = location.pathname;
    return (
      currentPath.includes("forgot-password") ||
      currentPath.includes("resetpassword")
    );
  };
  useEffect(() => {
    if (!session) {
      dispatch(removeSession);
      if (!isExceptionRoute) navigate("/login");
    }
  }, [session, location?.pathname]);

  useEffect(() => {
    appInit();
  }, [dispatch]);

  //!TEST FOR MERINFO BRANCH LAYOUT. NEEDS TO BE ADJUSTED IN LIVE VERSION TO BE SET BY URL/OTHER WAY
  const siteUrl = MERINFO_THEME === "true" ? "merinfo" : "";
  // useEffect(() => {
  //   setSiteUrl(window.location.host);
  // }, []);

  //!-----------------------------

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          path="/"
          element={
            session || isExceptionRoute() ? (
              <Layout siteUrl={siteUrl} />
            ) : (
              <Navigate to="/login" state={{ from: location }} replace />
            )
          }
        >
          <Route index element={<Home siteUrl={siteUrl} />} />
          <Route path="segments">
            <Route index element={<MyTargetGroups siteUrl={siteUrl} />} />
            <Route path="create-segment/:id" element={<CreateTargetGroup />} />
          </Route>
          {siteUrl === "merinfo" && (
            <>
               <Route path="exports" element={<Exports />}>
                Exporter
              </Route> 
              <Route path="lists" element={<Lists />}>
                Listor
              </Route>
            </>
          )}
          {siteUrl !== "merinfo" && (
            <>
              <Route path="offers" element={<MyOffers />} />
              <Route path="leads" element={<MyLeads />} />
              <Route path="campaigns">
                <Route index element={<MyCampaigns />} />
                <Route path="create-campaign/:id" element={<CreateCampaign />}>
                  <Route
                    index
                    path="type-of-campaign/:offerID?"
                    element={<TypeOfCampaign />}
                  />
                  <Route path="target-group" element={<TargetGroup />} />
                  <Route path="prioritize" element={<Prioritize />} />
                  <Route path="ad-channel" element={<AdChannel />} />
                  <Route
                    path="budget-schedule"
                    element={<BudgetAndSchedule />}
                  />
                  <Route path="ad-content" element={<AdContent />} />
                </Route>
              </Route>
              <Route path="accounts" element={<MyAccounts />} />
              <Route path="statistics" element={<Statistics />} />
            </>
          )}

          {userStatus === 1 && (
            <Route>
              <Route path="users" element={<UsersAccounts />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          )}
          {userStatus === 3 && (
            <Route>
              <Route path="users" element={<UsersAccounts />} />
              <Route path="customers" element={<Customers />} />

              <Route path="customersuccess" element={<CustomersSuccess />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="filters" element={<MyFilters />} />
              <Route path="activities" element={<Activities />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          )}
        </Route>

        { /* LOGIN ROUTE */}
        <Route
          path="login"
          element={
            session ? (
              <Navigate to="/" state={{ from: location }} replace />
            ) : (
              <LoginPage siteUrl={siteUrl} />
            )
          }
        />
        {/* RESET PASSWORD ROUTES */}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        {/* ALL OTHER ROUTES */}
        <Route
          path="*"
          element={<Navigate to="/login" state={{ from: location }} replace />}
        />
      </Route>
    </Routes>
  );
}

export default App;
