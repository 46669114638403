import { Col, Image, Row, Nav, Button } from "react-bootstrap";
import { SideMenu, MenuItem } from "features/navigation";
import { MdSettings, MdMenu, MdClose } from "react-icons/md";
import logo from "assets/img/autotarget-logo-blue.png";
import logoMerInfo from "assets/img/merinfo.png";
import { useState } from "react";
import "./style.scss";
import { Logout } from "features/authentication";
import { Link } from "react-router-dom";
import { getCustomerSettings } from "features/settings/api";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import useCheckUser from "hooks/useCheckUser";
import SettingDetailsOffCanvas from "features/settings/settings-details/SettingDetailsOffCanvas";

const SideBar = ({ siteUrl, setSiteUrl }) => {
  const userStatus = useCheckUser();
  const userRoles = {
    user: 2,
    admin: 1,
    superadmin: 3,
  };
  const { data: settings } = useQuery("customer", getCustomerSettings);

  const [showSettingDetails, setShowSettingDetails] = useState(false);

  const ifBigScreen = useMediaQuery({ query: "(min-width: 1224px)" });
  const ifMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const isMerinfoTheme = siteUrl === "merinfo";

  return (
    <>
      {ifMediumScreen ? (
        <>
          <Col
            xs={4}
            md={2}
            className={
              "px-3 pb-5 pt-3 d-flex align-items-center flex-column sidebar-container-medium"
            }
          >
            <Row className={`${!ifMediumScreen ? "mt-5" : ""}`}>
              <Col className="col-12 mb-5 flex-1 px-3">
                <Link to="/home">
                  <Image
                    fluid
                    src={siteUrl === "merinfo" ? logoMerInfo : logo}
                  />
                </Link>
              </Col>
            </Row>

            <Row className="flex-column h-100 w-auto align-items-center justify-content-between">
              <Col className="col-12 px-0 mx-0">
                <SideMenu
                  siteUrl={siteUrl}
                  settings={settings}
                  ifMediumScreen={ifMediumScreen}
                />
              </Col>

              <Col className="col-12">
                <Row className="gy-4">
                  <hr
                    style={{ width: "80%", margin: "auto", opacity: "0.05" }}
                  />
                  <Col className="col-12 mb-3 ">
                    <Row className="row-cols-auto justify-content-center align-items-center">

                      <Col
                        className={
                          ifBigScreen
                            ? "col-4"
                            : "col-12 d-flex justify-content-center"
                        }
                      >
                        <Image
                          roundedCircle
                          className="customer-logo"
                          src={
                            settings?.logoUrl
                              ? settings.logoUrl
                              : "https://picsum.photos/60"
                          }
                          width={60}
                          height={60}
                        />
                      </Col>

                      {ifBigScreen && (
                        <p className="text-md semi-bold customer-name text-center">
                          {settings?.customerName
                            ? settings.customerName
                            : "Kundnamn AB"}
                        </p>
                      )}
                    </Row>
                  </Col>

                  {!isMerinfoTheme && [userRoles.admin, userRoles.superadmin].includes(
                    userStatus
                  ) && (
                      <Col
                        className="col-12"
                        style={{ paddingLeft: ifBigScreen ? "30px" : "" }}
                        onClick={() => setShowSettingDetails(true)}
                        role="button"
                      >
                        <Row className="px-2">
                          <Col
                            className={
                              ifBigScreen
                                ? "col-3 d-flex align-items-center justify-content-center"
                                : "col-12 d-flex align-items-center justify-content-center"
                            }
                          >
                            <MdSettings
                              size={ifBigScreen ? 16 : 25}
                              color="#979A97"
                            />
                          </Col>
                          {ifBigScreen && (
                            <Col className="d-flex align-items-center col-8">
                              <p className="text-lg regular logout-text">
                                Mitt konto
                              </p>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}

                  <Col
                    className="col-12"
                    style={{ paddingLeft: ifBigScreen ? "30px" : "" }}
                  >
                    <Logout ifBigScreen={ifBigScreen} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      ) : (
        <>
          <Button
            variant="link"
            className="sidebar-toggle"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {sidebarOpen ? <MdClose size={30} /> : <MdMenu size={30} />}
          </Button>

          {sidebarOpen && (
            <Col
              xs={4}
              md={2}
              className={`px-3 pb-5 pt-3 d-flex align-items-center flex-column sidebar-container ${sidebarOpen ? "open" : ""
                }`}
            >
              <Row className="mt-5">
                <Col className="col-12 mb-5 flex-1 px-3">
                  <Link to="/home">
                    <Image
                      fluid
                      src={siteUrl === "merinfo" ? logoMerInfo : logo}
                    />
                  </Link>
                </Col>
              </Row>


              <Row className="flex-column h-100 w-auto align-items-center justify-content-between">
                <Col className="col-12">
                  <SideMenu settings={settings} sidebarOpen={setSidebarOpen} siteUrl={siteUrl} />
                </Col>

                <Col className="col-12">
                  <Row className="gy-4">
                    <hr
                      style={{ width: "80%", margin: "auto", opacity: "0.05" }}
                    />
                    <Col className="col-12 mb-3">
                      <Row>
                        <Col
                          className={
                            ifBigScreen
                              ? "col-4"
                              : "col-12 d-flex justify-content-center"
                          }
                        >
                          <Image
                            roundedCircle
                            src={
                              settings?.logoUrl
                                ? settings.logoUrl
                                : "https://picsum.photos/60"
                            }
                            width={60}
                            height={60}
                            className="customer-logo"
                          />
                        </Col>
                        {ifBigScreen && (
                          <Col className="col-8 d-flex align-items-end">
                            <p
                              className="text-md semi-bold customer-name"
                            // style={{
                            //   color: "rgb(188 188 188)",
                            //   marginBottom: "5px",
                            // }}
                            >
                              {settings?.customerName
                                ? settings.customerName
                                : "Kundnamn AB"}
                            </p>
                          </Col>
                        )}
                      </Row>
                    </Col>

                    {!isMerinfoTheme && [userRoles.admin, userRoles.superadmin].includes(
                      userStatus
                    ) && (
                        <Col
                          className="col-12"
                          style={{ paddingLeft: ifBigScreen ? "30px" : "" }}
                          onClick={() => setShowSettingDetails(true)}
                          role="button"
                        >
                          <Row className="px-2">
                            <Col
                              className={
                                ifBigScreen
                                  ? "col-3 d-flex align-items-center justify-content-center"
                                  : "col-12 d-flex align-items-center justify-content-center"
                              }
                            >
                              <MdSettings
                                size={ifBigScreen ? 16 : 25}
                                color="#979A97"
                              />
                            </Col>
                            {ifBigScreen && (
                              <Col className="d-flex align-items-center col-8">
                                <p className="text-lg regular logout-text">
                                  Mitt konto
                                </p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      )}
                    <Col
                      className="col-12"
                      style={{ paddingLeft: ifBigScreen ? "30px" : "" }}
                    >
                      <Logout ifBigScreen={ifBigScreen} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </>
      )}
      <SettingDetailsOffCanvas
        show={showSettingDetails}
        onClose={() => setShowSettingDetails(false)}
      />
    </>
  );
};

export default SideBar;
