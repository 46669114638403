import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Segments = {
  GET: {
    ALL: (
      page,
      limit,
      search,
      sortByProperty,
      desc,
      statusFilter,
      segmentType,
      isAiGenerated,
      segmentIDs
    ) => {
      const queryParams = [
        page && `page=${page}`,
        limit != undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchTerm=${search}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        desc && `desc=${true}`,
        statusFilter && `statusFilter=${statusFilter}`,
        segmentType !== undefined && `typeFilter=${segmentType}`,
        isAiGenerated !== undefined && `isAiGenerated=${isAiGenerated}`,
        segmentIDs !== undefined && `segmentIDs=${segmentIDs?.join(",")}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/segments/v1/${queryString}`).then(({ data }) => data)
          ).catch((x) => Promise.reject(x)),
        queryKey: ["segments", queryString],
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all segments \n Error: `, err),
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios.get(`/segments/v1/${id}`).then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["segment", id],
        enabled: !!id,
        //!Creates updating bug #3295
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) => {
          console.warn(`Failed to get segment of '${id}'\n Error: `, err);
        },
      });
    },
    PREVIEW: ({ id, search, groupBy, limit, disabled }) => {
      // https://app-ifgapi-prod-noeu-001.azurewebsites.net/swagger/index.html#operations-Segments-get_api_segments_v1__id__preview
      const queryParams = [
        limit !== undefined && `limit=${limit}`,
        groupBy && `groupBy=${groupBy}`,
        search && `search=${search}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: async () => {
          return axios.get(`/segments/v1/${id}/preview${queryString}`).then(({ data }) => data);
        },
        enabled: !!id ?? disabled,
        queryKey: ["segment", id, "preview", queryString],
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        retry: false,
      });
    },
    AIFILTERCONFIG: (id, restore) => {
      return useQuery({
        queryFn: async () => {
          const result = await criteria(id).catch((badInput) => badInput);
          if (result !== id) return;
          return axios
            .get(`/segments/v1/${id}/aifilterconfig?restore=${restore}`)
            .then(({ data }) => data);
        },
        queryKey: ["ai-segment", id],
        // staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) => {
          console.warn(`Failed to get segment of '${id}'\n Error: `, err);
        },
      });
    },
    MAPPINGORDERS: (id) => {
      return useQuery({
        queryFn: async () => {
          const result = await criteria(id).catch((badInput) => badInput);
          if (result !== id) return;
          return axios
            .get(`/segments/v1/${id}/mappingorders`)
            .then(({ data }) => data);
        },
        queryKey: ["segment-mapping-order", id],
        // cacheTime: 1000 * 60 * 30, // 30 minutes
        // retry: false,

        onError: (err) => {
          console.warn(
            `Failed to get segment mappingorder of '${id}'\n Error: `,
            err
          );
        },
      });
    },
  },

  POST: {
    SEGMENT: (segment) => {
      const queryClient = useQueryClient();
      return useMutation(async () => axios.post(`/segments/v1/`, segment), {
        onSuccess: () => {
          queryClient.invalidateQueries("segments");
          queryClient.invalidateQueries(["segment", segment.id]);
        },
        onError: (error) => {
          console.warn(
            `Failed to POST segment ${segment.id} \n Error: `,
            error
          );
          toast(`[Fel] Det gick inte att skapa en ny målgrupp`, {
            type: "error",
          });
        },
      });
    },
    COPY: (id) => {
      const queryClient = useQueryClient();
      return useMutation(async () => axios.post(`/segments/v1/${id}/copy`), {
        onSuccess: () => {
          queryClient.invalidateQueries("segments");
        },
        onError: (error) => {
          console.warn(`Failed to COPY segment ${id} \n Error: `, error);
          toast(`[Fel] Det gick inte att duplicera målgruppen`, {
            type: "error",
          });
        },
      });
    },
    AISEGMENTPREVIEW: (id) => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ id, newFilter }) =>
          Promise.resolve(
            axios.post(`/segments/v1/${id}/aifilterconfig/preview`, newFilter)
          ).catch((e) => Promise.reject({ error: e, id: id })),
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              predicate: ({ querykey }) => querykey?.includes("ai-segment"),
            });
            queryClient.invalidateQueries("segments");
          },
          onError: (error) => {
            console.warn(
              `Failed to POST aisegment preview ${id} \n Error: `,
              error
            );
          },
        }
      );
    },
    MAPPINGORDERS: (id) => {
      const queryClient = useQueryClient();
      return useMutation(
        async () => axios.post(`/segments/v1/${id}/mappingorders`),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["segment-mapping-order", id]);
            queryClient.invalidateQueries("segment-mapping-order");
          },
          onError: (error) => {
            console.warn(`Failed to COPY segment ${id} \n Error: `, error);
            toast(`[Fel] Det gick inte att duplicera målgruppen`, {
              type: "error",
            });
          },
        }
      );
    },
    EXPORT: () => {
      const queryClient = useQueryClient();
      return useMutation(
        async ({ id, include_phonenumber, include_phonenumber_operator, phoneLimit, email }) => {

          const queryParams = [
            include_phonenumber !== undefined && `include_phonenumber=${!!include_phonenumber}`,
            include_phonenumber_operator !== undefined && `include_phonenumber_operator=${!!include_phonenumber_operator}`,
            phoneLimit !== undefined && `phoneLimit=${phoneLimit}`,
            email !== undefined && `email=${!!email}`,
          ]?.filter((x) => x);

          const queryString =
            queryParams.length > 0 ? `?${queryParams.join("&")}` : "";


          return axios.post(`/segments/v1/${id}/export${queryString}`)
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["dataExports"] });
          },
        }
      );
    },
  },
  PUT: {
    SEGMENT: (segment) => {
      const queryClient = useQueryClient();
      return useMutation(
        async () => axios.put(`/segments/v1/${segment.id}`, segment),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("segments");
            queryClient.invalidateQueries(["segment", segment.id]);
          },
          onError: (error) => {
            console.warn(
              `Failed to PUT segment ${segment.id} \n Error: `,
              error
            );
          },
        }
      );
    },
    AIFILTERCONFIG: (id) => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ id, newFilter }) =>
          Promise.resolve(
            axios.put(`/segments/v1/${id}/aifilterconfig`, newFilter)
          ).catch((e) => Promise.reject({ error: e, id: id })),

        {
          onSuccess: () => {
            queryClient.invalidateQueries("ai-segments");
            queryClient.invalidateQueries(["ai-segment", id, true]);
            queryClient.invalidateQueries(["ai-segment", id, false]);
            queryClient.invalidateQueries(["ai-segment", "preview", id]);
            queryClient.invalidateQueries(["ai-segment"]);
          },
          onError: (error) => {
            console.warn(`Failed to PUT segment ${id} \n Error: `, error);
          },
        }
      );
    },
    MAPPINGORDERS: () => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ id, item }) =>
          Promise.resolve(
            axios
              .put(`/segments/v1/${id}/mappingorders/${item.id}`, item)
              .then(({ data }) => data)
          ).catch((err) => Promise.reject({ error: err, id: item.id })),

        {
          onSuccess: (id) => {
            queryClient.invalidateQueries(["segment-mapping-order", id]);
            queryClient.invalidateQueries("segments");
            queryClient.invalidateQueries("segment-mapping-order");
            queryClient.invalidateQueries(["segment", id]);
          },
          onError: (error) => {
            console.warn(`Failed to COPY segment ${id} \n Error: `, error);
            toast(`[Fel] Det gick inte att duplicera målgruppen`, {
              type: "error",
            });
          },
        }
      );
    },
  },
  // queryFn: async () => {
  //   const result = await criteria(id).catch((badInput) => badInput);
  //   if (result !== id) return;
  //   return axios
  //     .get(`/segments/v1/${id}/aifilterconfig`)
  //     .then(({ data }) => data);
  // },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/segments/v1/${id}`), {
        onSuccess: () => {
          queryClient.invalidateQueries("segments");
          queryClient.removeQueries(["segment", id]);
          toast("Målgrupp borttagen", { type: "success" });
        },
        onError: (error) => {
          console.warn(`Failed to delete segment ${id} \n Error:`, error);
          toast("Målgruppen gick inte att ta bort", { type: "error" });
        },
      });
    },
  },
};
